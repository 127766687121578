<template>
  <el-form ref="form" :model="form" label-width="180px" :rules="rules">
    <el-form-item label="品质部审批人" prop="nextApprover">
      <el-select v-model="form.nextApprover" filterable placeholder="请选择品质部审核人">
        <el-option
          v-for="item in qualityManagerTable"
          :key="item.account"
          :label="item.name"
          :value="item.account">
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item>
      <el-button size="mini" type="primary" @click="cancelButtonClick(false)" >取消</el-button>
      <el-button size="mini" type="success" @click="onSubmitButtonClick(false)" >启动流程</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import billInfoApi from '@/apis/apply/billInfo'
export default {
  props: {
    labelForm: {
      type: Object
    },
  },
  data() {
    return{
      form:{
        nextApprover:''
      },
      qualityManager:{},
      qualityManagerTable:[],
      rules: {
        nextApprover: [
          { required: true, message: '请选择品质部审核人', trigger: 'change' }
        ],
      }
    }
  },
  watch:{
    labelForm:{
        handler: function(newV,oldV){
        }
    }
  },
  created(){
    this.getManagerList()
  },
  methods:{
    getManagerList(){
      billInfoApi.getByKey("qualityManager").then(res =>{
        if(res.data && res.data.id){
          this.qualityManager = res.data
          if(res.data.value){
            this.qualityManagerTable = JSON.parse(res.data.value)
          }
        }
      })
    },
    cancelButtonClick(){
      this.$emit("close")
    }
    ,
    onSubmitButtonClick(){
       this.$refs['form'].validate((valid) => {
          if (valid) {
            this.$emit("selectQualityManager", this.form.nextApprover)
            this.$emit("close")
          } else {
            return false;
          }
        });
    }
  }
}
</script>

<style>
</style>