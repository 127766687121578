import api from '../api'
/**
 * 管理员用户相关api
 */
export class NewBillApi extends api {
  /**
   * 暂存
   * @param {*} form
   * @returns
   */
   temporaryStorage(form) {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/temporaryStorage`,
      method: 'post',
      data: form
    })
  }

  /**
   * 发起流程
   * @param {*} form
   * @returns
   */
   startIntance(form) {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/workflow/startIntance`,
      method: 'post',
      data: form
    })
  }



}

export default new NewBillApi('labelApply/newBill')