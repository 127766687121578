<template>
  <form>
    <div ref="addRef">
      <el-row v-if="!id || labelForm.status&&(labelForm.status.name==='TO_BE_CONFIRMED'||labelForm.status.name==='TERMINATION') && this.source !='myBills'" type="flex" justify="space-between" class="top" >
        <el-form-item> 
          <el-button  @click="handleCreate" type="primary" size="mini" icon="el-icon-plus" v-waves>添加申请</el-button>
          <el-upload style="display: inline-block;vertical-align:top;margin-left:10px;" size="mini" class="filter-item" ref="importor" :before-upload="beforeAllUpload" :auto-upload="false" :headers="myHeaders" :action="$EXCEL_UPLOAD_Label" :on-success="uploadAllDataSuccess" :on-error="onUploadError" :on-change="onChange" :show-file-list="false" accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" v-waves>
            <el-button type="primary" size="mini" v-waves icon="el-icon-upload2" >批量导入申请</el-button>
          </el-upload>
          <el-button style="margin-left:10px;" @click="downloadTemplate" type="text" size="mini" icon="el-icon-download" v-waves>导入模板下载</el-button>
          <span style="margin-left: 10px;color:red; font-size: 12px">* 相同的申请（ 物料代码、制造厂商二者相同 ）只保留一个</span>
        </el-form-item>
        <el-form-item>
          <el-button  @click="handleCancel" size="mini"  v-waves>取消</el-button>
          <el-button  @click="handleHold" type="info"  size="mini" icon="el-icon-document" v-waves>暂存</el-button>
          <el-button  @click="handleStart" type="success" size="mini" icon="el-icon-circle-check" v-waves>发起流程</el-button>
        </el-form-item>
      </el-row>
      <el-row v-if="labelForm.status&&labelForm.status.name==='IN_REVIEW' && source ==='todo'" class="top">
        <el-form-item> 
          <el-button @click="handleAudit" type="success" size="mini"  v-waves>审核单据</el-button>
        </el-form-item>
      </el-row>
    </div>
    <el-row class="label-table" >
      <el-table ref="labelTable" :max-height="tableHeight" stripe border fit size="mini" highlight-current-row :data="labelForm.labels" >
        <el-table-column type="index" label="序号" width="50" align="center"></el-table-column>
        <el-table-column v-if="id && splitShow" width="80" label="表单拆分" align="center">
          <template #default="scope">
            <el-tag type="success">{{scope.row.split}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="申请类型" min-width="70" align="center">
          <template #default="scope">
            {{scope.row.applyType&&scope.row.applyType.type}}
          </template>
        </el-table-column>
        <el-table-column label="支付类型" min-width="70" align="center">
          <template #default="scope">
            {{scope.row.payType&&scope.row.payType.type}}
          </template>
        </el-table-column>
        <el-table-column v-if="id" min-width="70" label="标签数（套）" prop="labelCount" align="center"></el-table-column>
        <el-table-column width="70" label="申请标签（套）" prop="applyCount" align="center"></el-table-column>
        <el-table-column v-if="id" width="70" label="发布标签（套）" prop="releaseCount" align="center"></el-table-column>
        <el-table-column width="90" label="物料代码" prop="model.materialCode" align="center"></el-table-column>
        <el-table-column min-width="150" label="型号" prop="model.name" align="center"></el-table-column>
        <el-table-column width="50" label="单位" prop="model.unit" align="center"></el-table-column>
        <el-table-column width="80" label="类型" align="center">
          <template #default="scope">
            <el-tag :type="showFilter(scope.row)">{{scope.row.model&&scope.row.model.type&&scope.row.model.type.title}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column min-width="100" label="代垫客户" prop="dealer.name" align="center"></el-table-column>
        <el-table-column min-width="100" label="制造商" prop="manufacturer.name" align="center"></el-table-column>
        <el-table-column width="50" label="制造商代码" prop="manufacturer.code" align="center"></el-table-column>
        <el-table-column width="50" label="联数" prop="model.page" align="center"></el-table-column>
        <el-table-column v-if="id" min-width="150" label="起始标签" prop="startLabel" align="center"></el-table-column>
        <el-table-column v-if="id" min-width="150" label="结束标签" prop="endLabel" align="center"></el-table-column>
        <el-table-column min-width="150" label="申请备注" show-overflow-tooltip prop="applyRemark" align="center"></el-table-column>
        <el-table-column v-if="!id ||labelForm.status&&(labelForm.status.name==='TO_BE_CONFIRMED'||labelForm.status.name==='TERMINATION')&& this.source !='myBills'" label="操作" align="center" min-width="180" fixed='right'>
          <template #default="scope">
            <el-button size="mini" type="primary" v-waves @click="onClickInfoButton(scope.row)">查看</el-button>
            <el-button size="mini" type="danger" v-waves @click="onClickDeleteButton(scope.row)" icon="el-icon-delete">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row>

    <el-dialog v-model="show" center :title="title" destroy-on-close :before-close="beforeClose">
      <el-form ref="label" size="mini" :model="label" label-position="Right" label-width="100px">
        <el-form-item label="申请类型" prop="applyType" :rules="[{required: true, message: '申请类型不能为空',trigger:'blur'}]">
            <el-select v-model="label.applyType" value-key="name" placeholder="请选择申请类型">
                <el-option v-for="p in applyTypes" :key="p.name" :label="p.type" :value="p">
                    <span>{{p.type}}</span>
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="支付类型" prop="payType" :rules="[{required: true, message: '支付类型不能为空',trigger:'blur'}]">
          <el-select v-model="label.payType" value-key="name" placeholder="请选择支付类型">
            <el-option v-for="p in payTypes" :key="p.name" :label="p.type" :value="p">
                <span>{{p.type}}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="代垫客户" prop="dealer" v-if="isSubstitute" :rules="[{required: true, message: '代垫客户不能为空',trigger:'blur'}]">
            <el-select v-model="label.dealer" value-key="id" placeholder="请选择代垫客户">
                <el-option v-for="(p,i) in dealers" :key="i" :label="p.name" :value="p">
                    <span>{{p.name}}</span>
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="物料代码" prop="model" :rules="[{required: true, message: '物料代码不能为空',trigger:'blur'}]">
          <el-button type="text" @click="handleSelectModel">添加物料代码</el-button>
          <el-table ref="model" stripe border fit size="mini" highlight-current-row :data="model">
            <el-table-column prop="materialCode" label="物料代码"></el-table-column>
            <el-table-column prop="name" label="型号" min-width="200px"></el-table-column>
            <el-table-column prop="unit" label="单位" width="50px"></el-table-column>
            <el-table-column label="类型" width="80px">
              <template #default="scope">
                <span>{{scope.row.type&&scope.row.type.title}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="page" label="联数"></el-table-column>
            <el-table-column align="center" width="50">
              <template #default="scope">
                <i class="el-icon-delete" style="cursor:pointer;" alt="移除" @click="handleRemoveModel(scope.row)"></i>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item label="制造厂商" prop="manufacturer" :rules="[{required: true, message: '制造商不能为空',trigger:'blur'}]">
            <el-select v-model="label.manufacturer" value-key="id" placeholder="请选择制造商">
                <el-option v-for="(p,i) in manufacturers" :key="i" :label="p.name" :value="p">
                    <span>{{p.name}}</span>
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="申请数量" prop="applyCount" :rules="[{required: true, message: '申请数量不能为空',trigger:'blur'}]" >
          <el-input-number v-model="label.applyCount" style="width:192px;" placeholder="请输入申请数量" :min="0" :max="1000000" clearable ></el-input-number>
        </el-form-item>
        <el-form-item label="申请备注" prop="applyRemark" >
          <el-input v-model="label.applyRemark" :rows="2" type="textarea" placeholder="请输入申请备注" maxlength="255" show-word-limit clearable ></el-input>
        </el-form-item>
        <el-form-item>
            <el-button size="mini" @click="onCancelButtonClick">取消</el-button>
            <el-button size="mini" type="primary" @click="onSubmitButtonClick(false)" :loading="loading">{{loading ? '提交中' : '确定'}}</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <Workflow :workflowNode="workflowNode" :workflowApi="billInfoApi" @complete="getLabelApply()"></Workflow>
    <filter-dialog ref="modelTable" title="选择物料代码" v-model="selecteModels.show" :rows="selecteModels.rows" :total="selecteModels.total" :index-column="true"
        :multiple-selected="multiple" width="80%" @page-info-change="handleModelPageInfoChange" :show-loading="selecteModels.showLoading"
        @close="handleModelDialogClose" @selected="handleModelSelected">
          <template #condition>
            <el-form-item>
              <el-input @keyup.enter="handleModelFilter" placeholder="输入关键字查询" v-model="selecteModels.listQuery.condition.keyword" clearable></el-input>
            </el-form-item>
            <el-form-item>
              <el-button class="filter-item" style="margin-left: 10px;" type="primary" v-waves icon="el-icon-search" @click="handleModelFilter">搜索</el-button>
            </el-form-item>
          </template>
          <template #default>
              <el-table-column prop="materialCode" label="物料代码" width="180"></el-table-column>
              <el-table-column  label="型号" prop="name"></el-table-column>
              <el-table-column prop="unit" label="单位"></el-table-column>
              <el-table-column label="类型">
                <template #default="scope">
                  <span>{{scope.row.type&&scope.row.type.title}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="page" label="联数"></el-table-column>
          </template>
      </filter-dialog>
      <el-dialog v-model="startupFormDialogShow" :append-to-body="true" width="50%">
          <component :is="startupForm" @close="startupFormDialogShow=false" :labelForm="labelForm" @selectQualityManager ='submitQualityManager'></component>
      </el-dialog>
  </form>
</template>

<script>
import dayjs from 'dayjs'
import commonApi from '@/apis/common'
import newBillApi from '@/apis/apply/newBill'
import billInfoApi from '@/apis/apply/billInfo'
import waves from '@/directive/waves' // 水波纹指令
import listConditionTemplate from '@/components/templates/listConditionTemplate'
import filterDialog from '@/components/filterDialog/filterDialog'
import Workflow from '@/components/workflow'
import { getToken } from '@/utils/auth'
import { mapGetters } from 'vuex'
import addQualityManager from './addQualityManager'
export default {
  props:["id"],
  data () {
    return {
      startupFormDialogShow: false,
      startupForm: null,
      splitShow: false,
      myHeaders: {
        Authorization: getToken(),
      },
      source:undefined,
      billInfoApi,
      labelForm:{
        labels: [],
      },
      show:false,
      loading:false,
      total: 0,
      label:{
      },
      model:[],
      manufacturers:[],
      isSubstitute:false,
      dealers:[],
      applyTypes:[
        {name: "SUPPLY_AGAIN", type:"补发标签"},
        {name: "APPEND", type:"新增标签"}
      ],
      payTypes:[
        {name: "SUBSTITUTE", type:"代垫"},
        {name: "SELF", type:"自付"}
      ],      
      date:[dayjs().subtract(1, 'month'),dayjs()],
      multiple:false,
      selecteModels:{
        show:false,
        listQuery: {
          condition: {
            keyword: undefined,
            enable: 'YES'
          },
          pageInfo:{
            pageIndex: 1,
            pageSize: 20,
            orders: []
          }
        },
        rows:[],
        total:0,
        showLoading:false
      },
      workflowNode: {
        businessObject: {
        }
      }
    }
  },
  components:{ filterDialog, Workflow, addQualityManager},
  computed: {
    ...mapGetters(['mainContainerHeight']),
    tableHeight: function () {
      return this.mainContainerHeight - (this.$refs.addRef?this.$refs.addRef.offsetHeight:40) -40
    }
  },
  watch:{
    "label.model.id":{
      handler: function(oldV,newV){
        this.getManufacturers()
      },
      deep: true
    },
    "label.payType.name":{
      handler: function(newV,oldV){
        if(newV === "SUBSTITUTE"){
          this.isSubstitute = true
          this.getDealers()
        }else{
          this.isSubstitute = false
          this.label.dealer = null
        }
      },
      deep: true
    }
  },
  mounted () {
  },
  methods: {
    submitQualityManager(nextApprover){
      if(!this.id){
        var labelApply = {
          status:{
            name:"TO_BE_CONFIRMED"
          },
          labels: this.labelForm.labels
        }
        this.labelForm = labelApply
      }
      this.labelForm.labelCount = this.caculateApplyCount()
      billInfoApi.temporaryStorage(this.labelForm).then(res => {
        if(res.data){
          res.data.nextApprover = nextApprover
          billInfoApi.startIntance(res.data).then(res => {
            this.$message.success("流程启动成功")
              this.skipToHoldWeb()
          }).catch(() => {
            this.getLabelApply()
          })
        }else{
          this.$message.error("流程启动失败")
        }
      })
    },

    showFilter (row) {
      return row.model&&row.model.type&&row.model.type.value === 'HOME_APPLIANCES' ? 'success' : 'warning'
    },
    handleCreate(){
      this.show = true
      this.title = '添加申请'
      this.label = {
        model:null
      }
    },
    downloadTemplate(){
      commonApi.downloadTemplate()
    },
    handleModelPageInfoChange(pageInfo){
      this.getModels(pageInfo)
    },
    handleModelFilter(){
      this.$refs.modelTable.changeCondition()
    },
    handleSelectModel(){
      this.selecteModels.show = true
    },
    handleModelSelected(data){
      this.model = [data]
      this.label.model = data
      // this.beforeClose()
    },
    handleRemoveModel(){
      this.label.model = null
      this.model = []
    },
    handleModelDialogClose(){
      this.selecteModels.listQuery.condition.name = undefined
      this.selecteModels.rows = []
      this.selecteModels.total = 0
      this.selecteModels.show = false
    },
    getModels(pageInfo){
      this.selecteModels.listQuery.pageInfo = pageInfo
      this.selecteModels.showLoading = true
      commonApi.getModelsPaged(this.selecteModels.listQuery)
        .then(response => {
          this.selecteModels.rows = response.data.content
          this.selecteModels.total = response.data.totalElements
          this.selecteModels.showLoading = false
        }).catch(() =>{
          this.selecteModels.showLoading = false
        })
    },
    getManufacturers(){
      if(this.label.model&&this.label.model.id){
        commonApi.getManufacturers(this.label.model.id).then(response => {
            this.manufacturers = response.data
        })
      }
    },
    getDealers(){
      commonApi.getDealers().then(response => {
          this.dealers = response.data
      })
    },
    onSubmitButtonClick(){
      this.$refs.label.validate().then(response => {
        this.labelForm.labels.push(Object.assign({},this.label))
        this.labelForm.labels = this.nodup()
        this.show = false
        this.beforeClose()
      })
    },
    beforeClose(){
      this.show = false
      this.$refs.label.resetFields()
      this.model = []
    },
    onCancelButtonClick(){
      this.beforeClose()
    },
    onClickInfoButton(row){
      this.show = true
      this.title = '编辑申请'
      this.label = row
      this.model = [row.model]
    },
    onClickDeleteButton(row){
      this.labelForm.labels = this.labelForm.labels.filter(label => label.model.id!==row.model.id || label.manufacturer.id!==row.manufacturer.id
       || label.applyType.name!==row.applyType.name)
    },
    handleCancel(){
      this.skipToHoldWeb()
    },
    /**
     * 跳转到暂存页面
     */
    skipToHoldWeb(){
      this.$router.pushTo(this.$router.routesConfig.applyHoldList)
    },
    handleHold(){
      if(this.id){
        this.labelForm.labelCount = this.caculateApplyCount()
        billInfoApi.temporaryStorage(this.labelForm).then(res => {
          this.$message.success("暂存成功")
          this.skipToHoldWeb()
        }).catch(() => {
          this.getLabelApply()
        })
      }else{
        // 新增暂存
        var labelApply = {
          status:{
            name:"TO_BE_CONFIRMED"
          },
          labelCount:this.caculateApplyCount(),
          labels: this.labelForm.labels
        }
        newBillApi.temporaryStorage(labelApply).then(res => {
          this.$message.success("暂存成功")
          this.skipToHoldWeb()
        })
      }
    },
    /**
     * 发起流程
     * 
     */
    handleStart(){
      this.startupFormDialogShow = true
      this.startupForm = 'addQualityManager'
      
      // if(!this.id){
      //   var labelApply = {
      //     status:{
      //       name:"TO_BE_CONFIRMED"
      //     },
      //     labelCount:this.caculateApplyCount(),
      //     labels: this.labelForm.labels
      //   }
      //   this.labelForm = labelApply
      // }
      // billInfoApi.temporaryStorage(this.labelForm).then(res => {
      //   billInfoApi.startIntance(res.data).then(res => {
      //     this.$message.success("流程启动成功")
      //       this.skipToHoldWeb()
      //   }).catch(() => {
      //     this.getLabelApply()
      //   })
      // })
    },
    getSum(total, label){
      return total + label.applyCount
    },
    caculateApplyCount(){
      return this.labelForm.labels.reduce(this.getSum, 0)
    },
    getLabelApply(){
      if(this.id){
        billInfoApi.getById(this.id).then(res =>{
          this.labelForm = res.data
          if(res.data.labels.length>0){
            this.splitShow = res.data.labels[0].split? true:false
          }
        })
      }
    },
    /**
     * 去重
     */
    nodup(){
      var result = []
      for(var i=0;i<this.labelForm.labels.length;i++){
        var temp = this.labelForm.labels[i]
        if(result.length === 0){
          result.push(temp)
        }else{
          result = result.filter(r => r.model.materialCode !== temp.model.materialCode || r.manufacturer.id !== temp.manufacturer.id)
          result.push(temp)
        }

      }
      return result
    },
    handleAudit(){
      if(!this.labelForm.processInstanceId){
        this.$message.error("流程ID为空不能审核")
      }
      this.workflowNode = {
        processInstanceId: this.labelForm.processInstanceId,
        assignee: this.$store.state.user.account,
        processDefinitionKey: this.labelForm.processDefinitionKey,
        businessKey: this.labelForm.id,
        businessObject: this.labelForm
      }
      //console.log('workflowNode',this.workflowNode)
    },
    beforeAllUpload (file) {
      // const name = file.name
      // if (!name.endsWith('.xlsx') && !name.endsWith('.xls')) {
      //   this.$message.error('只能上传EXCEL文档！')
      //   return false
      // }
    },
    uploadAllDataSuccess (index, response) {
      if(response.response.msg){
        this.$message({
          message: response.response.msg,
          type: 'warning'
        })
      }else{
        this.labelForm.labels = this.labelForm.labels.concat(index.data)
        this.labelForm.labels = this.nodup()
        this.$message({
          message: '导入完成',
          type: 'success'
        })
      }
    },
    onUploadError (err, file, fileList) {
      let errMessage = '导入失败，请稍后重试'
      let error
      try {
        err && (error = JSON.parse(err.message))
        switch (err.status) {
          case 400:
            errMessage = '错误的请求'
            break
          case 401:
            this.$store.dispatch('FedLogOut')
            errMessage = (error && error.msg) ? error.msg : '登录已超时，请重新登录'
            this.$router.replace({
              name: 'login',
              params: {
                lastPath: this.$router.history.current.fullPath
              }
            })
            break
          case 403:
            errMessage = '未授权请求'
            break
          case 404:
            errMessage = '请求的资源不存在'
            break
          case 500:
            errMessage = '服务器内部错误'
            if (error && error.status) {
              errMessage = error.msg ? error.msg : errMessage
            }
            break
          case 500:
            errMessage = '服务器内部错误'
            if (error && error.status && error.status > 1000) {
              errMessage = error.msg ? error.msg : errMessage
            }
            break
        }
      } catch (e) {}
      this.$message({
        message: errMessage,
        type: 'error'
      })
    },
    uploadSuccess (index, response) {
      console.log('inx',index, response)
      newBillApi
        .import(response.response.data)
        .then((res) => {
          if (res.data) {
            this.$message.success('导入成功！')
          } else {
            this.$message.error('导入失败！')
          }
        })
        .catch(() => {
        })
    },
    onChange (file, fileList) {
     // console.log('filelist', file.size / 1024 / 1024, fileList)
      if (file.status === 'success' || file.status === 'fail') {
        return
      }
      const name = file.name
      if (!name.endsWith('.xlsx') && !name.endsWith('.xls')) {
        this.$message.error('只能上传EXCEL文档！')
        return false
      } else if (file.size / 1024 / 1024 > 2) {
        this.$message.error('上传EXCEL文档大小不能超过2MB！')
        return false
      }
      this.$refs.importor.submit()
    }

  },
  created(){
    this.source = this.$route.query.source
    if(this.id){
      this.getLabelApply()
    }
  }
}
</script>

<style lang="scss" scoped>
.top {
  height: 50px;
}

.label-table {
  // padding: 10px 0;
  background-color: #f9fafc;
}
</style>