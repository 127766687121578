import api from '../api'
/**
 * 管理员用户相关api
 */
export class BillInfoApi extends api {

  /**
   * 暂存
   * @param {*} form
   * @returns
   */
   temporaryStorage(form) {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/temporaryStorage`,
      method: 'post',
      data: form
    })
  }

  /**
   * 发起流程
   * @param {*} form
   * @returns
   */
   startIntance(form) {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/workflow/startIntance`,
      method: 'post',
      data: form
    })
  }

  getTasks(data) {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/workflow/getTasks`,
      method: 'post',
      data:data
    })
  }

    /**
   * 查询流程历史记录
   * @param {*} form
   * @returns
   */
     getHistories(bussinessKey) {
      return this.request({
        url: `${this.servicePath}/${this.modulePath}/workflow/history/${bussinessKey}`,
        method: 'get'
      })
    }

    audit(data) {
      return this.request({
        url: `${this.servicePath}/${this.modulePath}/workflow/audit`,
        method: 'post',
        data:data
      })
    }

  /**
   * 暂存
   * @param {*} form
   * @returns
   */
  editTemporaryStorage(form) {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/editTemporaryStorage`,
      method: 'post',
      data: form
    })
  }
  getByKey(key){
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/getByKey/${key}`,
      method: "get"
    });
  }
  getVariables(taskId){
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/workflow/getVariables/${taskId}`,
      method: "get"
    });
  }
}

export default new BillInfoApi('labelApply/billInfo')